import jQuery from "jquery";

const mediatracking = {
  
    setupOnClickEvents: function () {     
        var pagename = jQuery("meta[name='pagename']").attr("content");
        if (pagename!=="") {      
            jQuery(".blpItem").each(function (index, element) {
                var extension = jQuery(element).data("extension");
                var documentTitle = jQuery(element).data("documenttitle");
                var documentLanguage = jQuery(element).data("documentlanguage");
                var linkHref = jQuery(element).data("linkhref");
                
                if (documentTitle !== undefined && extension !== undefined) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Download', 'Download " + extension + "- " + documentTitle + "- " + documentLanguage + "', 'Click BlupSpot on " + pagename + " page', 1);");
                    jQuery(element).addClass("monsido_download");
                }
                else {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Link', 'Link to " + linkHref + "', 'Click BlupSpot on " + pagename + " page', 1);");                    
                }

            });
            jQuery(".iconlink-link").each(function (index, element) {   
                var linkHref = jQuery(element).attr("href");
                if (linkHref !== undefined ) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Link', 'Icon Link to " + linkHref + "', 'Click on " + pagename + " page', 1);");
                }
            });
            jQuery(".image-link").each(function (index, element) {
                var linkHref = jQuery(element).attr("href");
                if (linkHref !== undefined) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Image Link', 'Image Text Link to " + linkHref + "', 'Click on " + pagename + " page', 1);");
                }
            });
                    
            jQuery(".sectionhero-link").each(function (index, element) {
                var linkHref = jQuery(element).attr("href");
                if (linkHref !== undefined) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Image Link', 'Section hero Link to " + linkHref + "', 'Click on " + pagename + " page', 1);");
                }
            });                             
        }         
  
    },

    init: function () {     
        window.addEventListener('mon-script-loaded', function () {
            mediatracking.setupOnClickEvents();
        });
    }
};

export default mediatracking
