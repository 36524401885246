﻿import jQuery from "jquery";

const cookieInfo = {
    init: function() {
        jQuery(".cookieinfo-renew").on("click",
            function () {
                event.preventDefault();
                if (typeof CookieConsent !== "undefined") {
                    CookieConsent.renew();
                }
            });
    }
};
export default cookieInfo;
