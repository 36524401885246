﻿import jQuery from "jquery"

const pardotForm = {
   gtmPardotDatalayerPush: function (event, formName) {
        //console.log("gtmPardotForm datalayer push");
        //console.log(event, formName);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: event,
            'formName': formName,
            'formPage': window.location.href
        });
    },

    resize: function() {
        jQuery.noConflict();
        var iframeUrl = jQuery('.pardot-iframe-resize').data('consent-src');
        jQuery('.pardot-iframe-resize').attr('src', iframeUrl);
        if (window.iFrameResize !== undefined) {
            window.iFrameResize({
                    onResized: function(messageData) { // Callback fn when resize is received
                        //jQuery('p#callback').html(
                        //    ' <b>Frame ID:</b> ' + messageData.iframe.id +
                        //    ' <b>Height:</b> ' + messageData.height +
                        //    ' <b>Width:</b> ' + messageData.width +
                        //    ' <b>Event type:</b> ' + messageData.type);
                    },
                    onMessage: function(messageData) { // Callback fn when message is received
                        //jQuery('p#callback').html(
                        //    '<b>Frame ID:</b> ' + messageData.iframe.id +
                        //    ' <b>Message:</b> ' + messageData.message);

                    },
                    onClosed: function(id) { // Callback fn when iFrame is closed
                        //jQuery('p#callback').html(
                        //    '<b>IFrame (</b>' + id +
                        //    '<b>) removed from page.</b>');
                    },
                    //bodyMargin: '20px',
                    checkOrigin: false,
                    log: false,
                    sizeHeight: true,
                    sizeWidth: false,
                    autoResize: true,
                    heightCalculationMethod: 'lowestElement'
                },
                '.pardot-iframe-resize');
        }
    },
    init: function () {
        window.addEventListener('message', function (event) {
            // Check the origin of the message is from Pardot
            if (event.origin !== 'https://info.chr-hansen.com') {
                return; // Ignore messages from unexpected origins
            }

            // Handle the submit message from Pardot iframe - to be able to push to datalayer
            var data = event.data;
            if (data.type === 'submit-clicked') {
                // Call the datalayer function with parameters 
                pardotForm.gtmPardotDatalayerPush(data.params.event, data.params.formName);
            }
        });


        window.addEventListener('CookieInformationConsentGiven',
            function(event) {
                if (window.CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
                    jQuery('.pardot-iframe-resize').attr('src', "");
                    (function() {
                        if (window.iFrameResize !== undefined) {
                            setTimeout(function() {
                                    pardotForm.resize();
                                },
                                500);                                                          
                        }
                        jQuery('.pardotFormNoConsent').hide();
                        jQuery('.pardotFormNoConsent').removeClass('d-flex');
                        //console.log("reload after cookie accept");
                    })();
                }
                if (!window.CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
                    //remove from iframe scr - to be able to reload and establish observable and messages with pardot
                    jQuery('.pardot-iframe-resize').attr('src', "");
                    (function() {
                        jQuery('.pardotFormNoConsent').show();
                        jQuery('.pardotFormNoConsent').addClass('d-flex');
                    })();
                }
            },
            false);
    }
};

export default pardotForm;
