import jQuery from "jquery";

const mediatracking = {
  
    setupOnClickEvents: function () {
        var pagename = jQuery("meta[name='pagename']").attr("content");
        if (pagename!=="") {      
            jQuery(".cta-box").each(function (index, element) {                  
                var linkHref = jQuery(element).attr("href");
                if (linkHref !== undefined && pagename !== undefined) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Link', 'CTA Link to " + linkHref + "', 'Click on " + pagename + " page', 1);");
                }
            });
            jQuery(".ctateaser").each(function (index, element) {  
                var linkHref = jQuery(element).attr("href");
                if (linkHref !== undefined && pagename !== undefined) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Link', 'CTA teaser box Link to " + linkHref + "', 'Click on " + pagename + " page', 1);");
                }
            });
            jQuery(".heroimage-link").each(function (index, element) {
                var linkHref = jQuery(element).attr("href");
                if (linkHref !== undefined && pagename !== undefined) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Image Link', 'Hero Image Link to " + linkHref + "', 'Click on " + pagename + " page', 1);");
                }
            });
                    
            jQuery(".herovideolink").each(function (index, element) {
                var linkHref = jQuery(element).attr("href");
                if (linkHref !== undefined && pagename !== undefined) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Image Link', 'Hero Video Link to " + linkHref + "', 'Click on " + pagename + " page', 1);");
                }
            });    
            jQuery(".linkelement-link").each(function (index, element) {
                var linkHref = jQuery(element).attr("href");
                if (linkHref !== undefined && pagename !== undefined) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Link', 'Link Elemente Link to " + linkHref + "', 'Click on " + pagename + " page', 1);");
                }
            }); 

        } 
    },

    init: function () {     
        window.addEventListener('mon-script-loaded', function () {
            mediatracking.setupOnClickEvents();
        });
    }
};

export default mediatracking
