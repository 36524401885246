import jQuery from "jquery"

const videolist = {
    onSearchSubmit: function () {
        jQuery(".videolist__filter__form").on("submit", function (e) {
            e.preventDefault();
            var $this = jQuery(this);

            var album = $this.find("select").val();
            var search = $this.find('input[type="text"]').val();
            var sortby = jQuery("[data-sort].selected").data("sort");
            var sitename = jQuery(".videolist").data("sitename");
            var language = jQuery(".videolist").data("language");

            videolist.getResults(album, search, sortby, sitename, language, 1);

        });
    },
    onLinkClick: function (element) {
        var $this = jQuery(element);
        var sitename = jQuery(".videolist").data("sitename");
        var language = jQuery(".videolist").data("language");
        var page = $this.data("page");
        var sortby = $this.data("sortby");
        var album = $this.data("album");
        var search = $this.data("search");

        videolist.getResults(album, search, sortby, sitename, language, page);
    },
    getResults: function (album, search, sortby, sitename, language, page) {

        var url = "/api/feature/video/ListVideos";
        var jsonObject = {
            "Album": album,
            "Search": search,
            "SortBy": sortby,
            "Sitename": sitename,
            "Language": language,
            "Page": page
        }
        jQuery.ajax({
            type: "POST",
            url: url,
            datatype: "application/json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(jsonObject),
            success: function (result) {
                videolist.showResult(result);
            }
        });
    },
    showResult: function (result) {
        jQuery(".videolist").replaceWith(result);
        videolist.init();
    },
    initSelectFilter: function () {
        jQuery(".videolist__filter__form select").on("change", function (e) {
            e.preventDefault();
            jQuery(".videolist__filter__form").trigger("submit");
        });
    },
    initPagination: function () {
        jQuery("#video-paging > ul").find("li > a").on("click", function (e) {

            videolist.onLinkClick(this);
        });
    },
    initSortBy: function () {
        jQuery(".videolist__filter__form").find("div > span > a").on("click", function (e) {
            e.preventDefault();

            videolist.onLinkClick(this);
        });
    },
   

    init: function () {
        videolist.onSearchSubmit();
        videolist.initSelectFilter();
        videolist.initPagination();
        videolist.initSortBy();      


        window.addEventListener('CookieInformationConsentGiven', function (event) {
            if (window.CookieInformation.getConsentGivenFor('cookie_cat_functional')) {
                (function () {
                    jQuery('.videoNoConsent').hide();
                    jQuery('.videoNoConsent').removeClass('d-flex');
                })();
            }
            if (!window.CookieInformation.getConsentGivenFor('cookie_cat_functional')) {
                (function () {
                    jQuery('.videoNoConsent').show();
                    jQuery('.videoNoConsent').addClass('d-flex');
                })();
            }
            if (window.CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
                (function () {
                    jQuery('.video23spot').show();
                    jQuery('.video23spot').addClass('d-flex');
                })();
                (function () {                  
                    jQuery('.ytVideoNoConsent').hide();
                    jQuery('.ytVideoNoConsent').removeClass('d-flex');
                })();
            }
            if (!window.CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
               
                (function () {
                    jQuery('.video23spot').hide();
                    jQuery('.video23spot').removeClass('d-flex');
                })();
                   
                (function () {
                    jQuery('.ytVideoNoConsent').show();
                    jQuery('.ytVideoNoConsent').addClass('d-flex');
                })();
            }
        }, false);

    }

}

export default videolist;


