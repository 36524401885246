import jQuery from "jquery"

(function ($) {
    $.QueryString = (function (paramsArray) {
        let params = {};

        for (let i = 0; i < paramsArray.length; ++i) {
            let param = paramsArray[i]
                .split('=', 2);

            if (param.length !== 2)
                continue;

            params[param[0]] = decodeURIComponent(param[1].replace(/\+/g, " "));
        }

        return params; 

    })(window.location.search.substr(1).split('&'))
})(jQuery);

const search = {
    pageNo: 1,
    init: function () {
        jQuery("#search__button").on("click", function () {
            search.monsidoTracking();
            search.pageNo = 1;           
            search.searchContent();       
        });

        jQuery("#search__field").on("keydown",
            function (e) {
                var keyCode = e.keyCode || e.which;
                if (keyCode === 13) {
                    search.pageNo = 1;
                    search.searchContent();
                }
            });

        jQuery(document).on("click", "#search__button__more", function () {
            search.showMore();
        });

        jQuery(document).on("change", ".search__filters__list__item input[type='checkbox']", function () {
            search.pageNo = 1;
            search.searchContent();
        });     
      
        search.onLoad();
    },
    searchContent: function () {
        var url = "/api/feature/Search/SearchResult";
        var searchField = jQuery("#search__field")
        var terms = searchField.val().trim();
        var sitename = searchField.data('sitename');
        var language = searchField.data('language');
        var filters = search.getFilters();

        if (terms) {          
            var jsonObject = {
                "Terms": terms,
                "Filter": filters,
                "PageNo": search.pageNo,
                "Sitename": sitename,
                "Language": language
            };

            jQuery.ajax({
                type: "POST",
                url: url,
                datatype: 'application/json',
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(jsonObject),
                success: function (result) {
                    search.showResult(result);
                  
                }
            });
        }
    },
    monsidoTracking: function () {     
        var searchField = jQuery("#search__field")
        var terms = searchField.val().trim(); 
        if (window.monsido_functions && terms !== "")
        {                
            window.monsido_functions.trackSearch(terms, 1);
        }           
    },

    getFilters: function () {

        var checkedElems = jQuery("#search__filters").find(".search__filters__list__item input:checkbox:checked");
        var filters = [];
        for (var i = 0; i < checkedElems.length; i++) {
            filters.push(jQuery(checkedElems[i]).val());
        }
        return filters;
    },
    showResult: function (result) {
        jQuery("#search__results").html(result);
    },
    showMore: function () {
        search.pageNo += 1;
        search.searchContent();
    },
    onLoad: function () {
	    if (jQuery("#search__field").length !== 0) {
		    var term = jQuery.QueryString["term"];
		    if (term) {
			    jQuery("#search__field").val(term);
			    search.pageNo = 1;
			    search.searchContent();
		    }
	    }
    }
}

export default search;
