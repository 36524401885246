import jQuery from "jquery";

const scheduledEvents = { 

    setupOnClickEvents: function () { 
        var pagename = jQuery("meta[name='pagename']").attr("content");
        if (pagename!=="") {                   
            jQuery(".scheduledLink").each(function (index, element) {
                var headline = jQuery(element).children('h2').text();              
                var pagename = jQuery("body").data('pagename');
                if (headline !== '' && pagename !== undefined) {
                    jQuery(element).attr('onclick', "window.monsido_functions.trackEvent('Scheduled Event Link', 'Event Link for " + headline + " ', 'Click Scheduled Event on " + pagename + " page', 1);");
                }
            });
                   
        } else {
            console.log("monsido not available in editor");
        }      
    },
  
    init: function () {
        window.addEventListener('mon-script-loaded', function () {
            scheduledEvents.setupOnClickEvents();
        });          
    }
};

export default scheduledEvents
