import jQuery from "jquery";

const teaserAnimation = {
    animateanchor: function () {
        jQuery(".page-content a").on("click", function (event) {
            var target = jQuery(this).attr("href");
            // Smoothly scroll to the target section if it's an anchor link
            if (target.startsWith("#")) {
                //check if the anchor exists in DOM - somewhere to scroll to
                var anchorExists = jQuery(target).length > 0;

                  //sometimes the name attribute is used instead of IDs -
                var nameValue = target.replace("#", "");
                var nameExists = jQuery("[name='" + nameValue + "'").length > 0;
                //console.log("name existst : " + nameExists);
                if (anchorExists) {
                 /*   console.log(anchorExists + " href : " + target)*/
                    var offset = jQuery(target).offset().top;
                    event.preventDefault();
                    jQuery("html, body").animate({
                        scrollTop: offset
                    }, 1000);
                }
                else if(nameExists) {
                   /* console.log(anchorExists + " name : " + target)*/
                    var nameanchor = jQuery("[name='" + nameValue + "'");
                    var offset = jQuery(nameanchor).offset().top;
                    event.preventDefault();
                    jQuery("html, body").animate({
                        scrollTop: offset
                    }, 1000);
                }
               
            }
        });
    },


    init: function () {
        teaserAnimation.animateanchor();
    }  
    
};

export default teaserAnimation
