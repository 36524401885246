﻿import jQuery from "jquery";

const topics = {
    filterPattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
    getRequestData: function(filter) {
        var jsonObject = {
            "Term": null,
            "Filters": [ filter ],
            "Month": null,
            "Year": null,
            "PageNo": 0
        };

        return jsonObject;
    },
    navigate: function(payload) {
        var form = document.createElement('form');
        form.style.visibility = 'hidden'; // no user interaction is necessary
        form.method = 'POST'; // forms by default use GET query strings
        form.action = jQuery(".topics-list").data("newsroomurl");
        for (var key in Object.keys(payload)) {
            var input = document.createElement('input');
            input.name = key;
            input.value = payload[key];
            form.appendChild(input); // add key/value pair to form
        }
        document.body.appendChild(form); // forms cannot be submitted outside of body
        form.submit(); // send the payload and navigate
    },
    /**
    * sends a request to the specified url from a form. this will change the window location.
    * @param {string} path the path to send the post request to
    * @param {object} params the paramiters to add to the url
    * @param {string} [method=post] the method to use on the form
    */
    post: function (path, params, method = 'post') {

        // The rest of this code assumes you are not using a library.
        // It can be made less wordy if you use one.
        const form = document.createElement('form');
        form.method = method;
        form.action = path;

        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = params[key];

                form.appendChild(hiddenField);
            }
        }

        document.body.appendChild(form);
        form.submit();
    },
    init: function () {
        jQuery(".topics-list [data-tagid]").on("click", function () {
            event.preventDefault();
            var elm = jQuery(this);
            var tagId = elm.data("tagid");
            if (topics.filterPattern.test(tagId)) {
                topics.post(jQuery(".topics-list").data("newsroomurl"), topics.getRequestData(tagId));
            }
        });
    }
};

export default topics;
