import jQuery from "jquery"
import config from "./poultryCalculator.config.json"

let PoultryCalculator = function (instance) {
    var self = this;
    var $instance = jQuery(instance);
    var dataSettings = $instance.attr('data-settings') ? JSON.parse($instance.attr('data-settings')) : {};
    var dataSettingsConfig = dataSettings.config || {};
    this.config = jQuery.extend({}, config, dataSettingsConfig) // Overwrite data-settings config with standard config if set

    /**
     * Get weighted results and find the recommended product to show in UI
     * @param {Event} e 
     * @returns {Void}
     */
    this.calculate = function (e) {
        // Remove previous recommendations from UI
        $instance.find('.js-result-item').removeClass('is-recommended-starter is-recommended-finisher');

        var weightedStarterResults = self.getWeightedResults(['salmonella', 'outbreaks']);
        var weightedFinisherResults = self.getWeightedResults(['performance', 'immunity', 'cost']);

        var starterResult = self.showRecommendedProduct(weightedStarterResults);
        var finisherResult = self.showRecommendedProduct(weightedFinisherResults);

        // Set appropriate recommandations in UI
        $instance.find('.js-starter ' + starterResult.resultSelector).addClass('is-recommended-starter');
        $instance.find('.js-finisher ' + finisherResult.resultSelector).addClass('is-recommended-finisher');
    };

    /**
     * Iterate over a result array and find the highest value
     * @param {Array} result 
     * @returns {String} product name
     */
    this.showRecommendedProduct = function (result) {
        result = result || [];

        var product = self.config.products;
        var recommendedProduct = {
            name: key,
            value: 0
        };

        for (var key in result) {
            if (result[key] == null) continue;

            if (result[key] > recommendedProduct.value) {
                recommendedProduct.name = key;
                recommendedProduct.value = result[key];
            }
        }

        return product[recommendedProduct.name];
    };

    /**
     * Calculate the weighed results for specified key indicators
     * @param {Array} keyIndicators 
     * @returns {Array} result
     */
    this.getWeightedResults = function (keyIndicators) {
        keyIndicators = keyIndicators || [];

        var result = [];
        var calculations = self.config.calculations;

        for (var key in calculations) {
            // Only get results from keyIndicators
            if (keyIndicators.indexOf(key) === -1) continue;

            for (var name in calculations[key]) {
                result[name] = result[name] ? result[name] + calculations[key][name] : calculations[key][name];
            }
        }

        return result;
    };

    /**
     * EventListener for a slider value change, to set the values for later calculations
     */
    this.sliderChangeHandler = function () {
        var weights = self.config.weights;

        $instance.find('.js-product-recommendation__slider').each(function (index, item) {
            var $this = jQuery(item);
            var name = $this.attr('name');

            for (var key in self.config.calculations[name]) {
                if (self.config.calculations[name][key] == null) continue;

                // Set the weighted calculation for later comparison
                self.config.calculations[name][key] = parseInt($this.val(), 10) * weights[name][key];
            }
        });

        // Trigger UI update and recalculate
        self.calculate();
    };

    // Set slider event listener within the class
    $instance.find('.js-product-recommendation__slider').on('change', self.sliderChangeHandler);

    // If preset wanted, change value of each slider
    if (self.config.presetCalculator) {
        $instance.find('.js-product-recommendation__slider').each(function (index, item) {
            var $this = jQuery(item);
            var name = $this.attr('name');
            var value = self.config.presetCalculatorValues[name];

            // Set value and trigger change event
            $this.val(value);
            $this.change();
        });
    }
};

let poultryCalculator = {
    /**
     * Init the PoultryCalculator class for each instance of .js-product-recommendation
     */
    init: function (selector) {
        if (!selector) return;

        var $instances = jQuery(selector);
        $instances.each(function (index, instance) {
            new PoultryCalculator(instance);
        });
    }
}

export default poultryCalculator;
