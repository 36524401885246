﻿import jQuery from "jquery";

const locationmodule = {

    setupOnClickEvents: function () {   
        var pagename = jQuery("meta[name='pagename']").attr("content");
        if (pagename!=="") {          
            jQuery(".location-email").each(function (index, element) {                      
                jQuery(element).attr('onclick', "window.monsido_functions.trackEvent('Contact Link', 'Location Email', 'Click Location Email link on " + pagename + " page', 1);");               
            });
            jQuery(".location-phone").each(function (index, element) {
                jQuery(element).attr('onclick', "window.monsido_functions.trackEvent('Contact Link', 'Location Phone', 'Click Location phone link on " + pagename + " page', 1);");                
            });
            jQuery(".location-fax").each(function (index, element) {
                jQuery(element).attr('onclick', "window.monsido_functions.trackEvent('Contact Link', 'Location Fax', 'Click Location Fax link on " + pagename + " page', 1);");               
            });             
        }
    },

    init: function () {
        window.addEventListener('mon-script-loaded', function () {
            locationmodule.setupOnClickEvents();
        });
    }
};

export default locationmodule






