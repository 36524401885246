import jQuery from "jquery"

const AnchorMenu = {
    setScroll: function () {
        jQuery(".anchor--back-to-top").click(function () {
                $('html, body').animate({
                    scrollTop: 0
                });
        });
    },

    buildMenu: function () {
        if (jQuery('.anchor-target').length) {
            // Generate anchor menu
            var $anchorMenuContainer = jQuery('<nav class="anchor-nav anchorcontainer px-3"><ul class="anchor-nav__list"></ul></nav>');
            jQuery('.anchor-target').each(function () {
                $anchorMenuContainer.find('.anchor-nav__list').append('<li class="anchor-nav__list__item"><a href="#' + jQuery(this).data('anchor') + '">' + jQuery(this).data('anchor-title') + '</a></li>');
            });
            //append the anchor list to the first article tag
            jQuery('article').first().prepend($anchorMenuContainer);

            // Anchor navigation
            jQuery('.anchor-nav a').on('click', function (e) {
                e.preventDefault()
                var href = jQuery(this).attr('href');
                jQuery('html, body').animate({
                    scrollTop: jQuery('.anchor-target[data-anchor="' + href.replace('#', '') + '"]').offset().top - (jQuery('header').height() + 75)
                });
            });
        }
    },
    init: function () {
        AnchorMenu.buildMenu();
        AnchorMenu.setScroll();
    }
}

export default AnchorMenu;
