import jQuery from "jquery"

const iframe = {
    calcHeight: function () {
        //console.log(jQuery('iframe').contents().height() + ' is the height');
        jQuery('.eurolan-autoheight').each(function () {
            EurolandToolIntegrationObject.set(jQuery(this).get(0));
        });
    },
    init: function () {
        jQuery(document).ready(function () {
            iframe.calcHeight();
        });
    }
}
export default iframe;
