﻿import jQuery from "jquery"

const videoTeaser = {
    init: function () {
        jQuery(document).ready(function(){
            jQuery('video').bind('contextmenu',function() { return false; });
        });
    }
}
export default videoTeaser;

