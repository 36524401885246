import jQuery from "jquery";
const relatedcontent = {
    setupOnClickEvents: function () {
        var pageedit = jQuery("body").hasClass('pagemode-edit');
        var pagepreview = jQuery("body").hasClass('pagemode-preview');
        if (!pageedit && !pagepreview) {
            var pagename = jQuery("body").data('pagename');           
            jQuery(".relatedlist__item-link").each(function (index, element) {
                var href = jQuery(element).attr("href");
                let url = href.split('?')[0]
                if (url.indexOf("/_/media/files") >= 0) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Download', 'Related Content Download link " + url + "', 'Click on " + pagename + " page', 1);");
                    jQuery(element).addClass("monsido_download");
                }
            });
        }
    },

    init: function () {
        window.addEventListener('mon-script-loaded', function () {
            relatedcontent.setupOnClickEvents();
        });
    }
};
export default relatedcontent
