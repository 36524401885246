import jQuery from "jquery"

const silageCalculator = {
    nodeClick: function () {
        jQuery(".product-calc-list-items button").on("click", function (event) {
            var thisNode = jQuery(this);
            jQuery(thisNode).siblings('button').attr("aria-expanded", "false");
            jQuery(thisNode).siblings().find('button').attr("aria-expanded", "false");
            jQuery(thisNode).attr("aria-expanded", "true");
    
            var target = jQuery(thisNode).data("target");
            jQuery(target).collapse("show");
         
            var classname = jQuery(thisNode).attr('class').split(' ')[0];
            //console.log(target);
            jQuery("." + classname).each(function () {
                if (jQuery(this).attr('data-target') !== target) {
                    jQuery(jQuery(this).data("target")).collapse("hide");

                } else {
                    jQuery(jQuery(this).data("target")).collapse("show");
                }
            });

            if (jQuery(this).attr('class').indexOf('show')) {
                var childNode = jQuery(target).find("[data-node='child']");
                childNode.each(function () {
                    var thisClass = jQuery(this).attr('class');
                    if (thisClass.indexOf('show')) {
                        jQuery(this).removeClass("show");
                    }
                });
            }

            if (target.length && jQuery(thisNode).hasClass("level-1") && jQuery("#IsMobileView").is(":visible")) {
                event.preventDefault();
                jQuery('html,body').animate({
                    scrollTop: jQuery(thisNode).offset().top -50
                }, 1000);
            }
        });
    },
  
    countrySelectorClick: function () {
        jQuery('#product-calc-list').change(function() {
            var option = jQuery('option:selected', this).attr('data-id');
            jQuery(".box").hide();
            jQuery("." + option).show();
        });
    },

    init: function () {
        jQuery(document).ready(function () {
            silageCalculator.nodeClick();
            silageCalculator.countrySelectorClick();
        });
    }
};

export default silageCalculator;
