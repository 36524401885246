import jQuery from "jquery"

const internalAnchor = {
    setScroll: function () {
        jQuery(".anchor-to-top").click(function () {
            jQuery('html, body').animate({
                scrollTop: 0
            });
        });
    },

    buildMenu: function () {
        if (jQuery('.anchor-targeting').length) {
            // Generate anchor menu
            var $anchorMenuContainer = jQuery('<nav class="anchor-nav"><ul class="anchor-nav__list"></ul></nav>');
            jQuery('.anchor-targeting').each(function () {
                $anchorMenuContainer.find('.anchor-nav__list').append('<li class="anchor-nav__list__item"><a href="#' + jQuery(this).data('anchor') + '">' + jQuery(this).data('anchor-title') + '</a></li>');
            });
            //append the anchor list to the first article tag
            jQuery('article').first().prepend($anchorMenuContainer);

            // Anchor navigation
            jQuery('.anchor-nav a').on('click', function (e) {
                e.preventDefault()
                var href = jQuery(this).attr('href');
                jQuery('html, body').animate({
                    scrollTop: jQuery('.anchor-targeting[data-anchor="' + href.replace('#', '') + '"]').offset().top - (jQuery('header').height() + 75)
                });
            });
        }
    },

    init: function () {
        jQuery(document).ready(function() {
            internalAnchor.buildMenu();
            internalAnchor.setScroll();
        });


    }
}

export default internalAnchor;
