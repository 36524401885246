import $ from "jquery"

const eurolandxml = {
	
    init: function() {

        var $eurolandXmlField = $('[data-eurolandxml]');

        if ($eurolandXmlField.length > 0) {
            var url = $eurolandXmlField.data('eurolandxml');

            $.ajax({
                type: "GET",
                url: url,
                dataType: "xml",
                success: function(xmlDoc) {

                    var xmlArray = eurolandxml.getXMLToObject(xmlDoc);

                    $.each(xmlArray.Euroland.Ticker, function(key, value) {
                        $eurolandXmlField.find('[data-eurolandkey='+ key +']').text(value);
                        if (key == "ChangePercent") {
                            $eurolandXmlField.find('[data-eurolandkey='+ key +']').addClass( (Math.round(value) < 0 ? "negative" : "positive") )
                        }
                    });
                },
                error: function() {
                    alert("An error occurred while processing XML file.");
                }
            });
        }
    },

    getXMLToObject: function(xmlDoc) {

        var thisObject = new Object();
        //Check XML doc
        if($(xmlDoc).children().length > 0){
            //Foreach Node found
            $(xmlDoc).children().each(function(){    
                if($(xmlDoc).find(this.nodeName).children().length > 0){
                    //If it has children recursively get the inner array
                    var NextNode = $(xmlDoc).find(this.nodeName);
                    thisObject[this.nodeName] = eurolandxml.getXMLToObject(NextNode);
                } else {
                    //If not then store the next value to the current array
                    thisObject[this.nodeName] = $(xmlDoc).find(this.nodeName).text();
                }
            });
        }
        return thisObject;
    }
}
export default eurolandxml;

