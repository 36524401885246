
import jQuery from "jquery"

const share = {
    showShareMenu: function () {
        jQuery('.share-this-link').on('click', function (e) {
            e.preventDefault();
            var $this = jQuery(this).find('.overlay-link-share-this');
            window.shareData = $this.data();
            jQuery('.share-menu').show();
            jQuery('.share-menu').css({
                top: '45%',
                right: $this.parent().width() + 30
            });
        });
    },
    hideShareMenu: function (event) {
        event.preventDefault();
        jQuery('.share-menu').hide();
    },
    getVertivalMid: function () {
        return (jQuery(window).height() / 2 - 275);
    },
    getHorizontalMid: function () {
        return (jQuery(window).width() / 2 - 225);
    },
    setupMonsidoTracking: function () {
        var pagename = jQuery("meta[name='pagename']").attr("content");
        if (pagename != "") {
            jQuery('.share-menu .icon-pinterest').on('click', function (e) {
                window.monsido_functions.trackEvent('Share', 'Click on Share', 'Click on Share Pinterest on ' + pagename + ' page', 1);
            });
            jQuery('.share-menu .linkedin').on('click', function (e) {
                window.monsido_functions.trackEvent('Share', 'Click on Share', 'Click on Share LinkedIn on ' + pagename + ' page', 1);
            });
            jQuery('.share-menu .twitter').on('click', function (e) {
                window.monsido_functions.trackEvent('Share', 'Click on Share', 'Click on Share Twitter on ' + pagename + ' page', 1);              
            });
            jQuery('.share-menu .facebook').on('click', function (e) {
                window.monsido_functions.trackEvent('Share', 'Click on Share', 'Click on Share Facebook on ' + pagename + ' page', 1);
            });
            jQuery('.share-menu .mail').on('click', function (e) {
                window.monsido_functions.trackEvent('Share', 'Click on Share', 'Click on Share Mail on ' + pagename + ' page', 1);
                //console.log("mail click");
            });
        }
    },
    initSocialNetworks: function () {
        var pagename = jQuery("meta[name='pagename']").attr("content");
        if (pagename != "") {
            jQuery('.share-menu .icon-pinterest').on('click', function (e) {              
                e.preventDefault();
                jQuery('body').removeClass('overlay overlay-show-share-this');
                window.open('https://pinterest.com/pin/create/button/?url=' + encodeURIComponent(window.shareData.url) + '&media=' + encodeURIComponent(window.shareData.image) + '&description=' + encodeURIComponent(window.shareData.summary), 'Pinterest Share', 'height=450, width=550, top=' + share.getVertivalMid() + ', left=' + share.getHorizontalMid() + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
            });
            jQuery('.share-menu .linkedin').on('click', function (e) {
                e.preventDefault();
                jQuery('body').removeClass('overlay overlay-show-share-this');
                window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(window.shareData.url) + '&title=' + encodeURIComponent(window.shareData.title) + '&summary=' + encodeURIComponent(window.shareData.summary), 'LinkedIn Share', 'height=450, width=550, top=' + share.getVertivalMid() + ', left=' + share.getHorizontalMid() + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
            });
            jQuery('.share-menu .twitter').on('click', function (e) {
                e.preventDefault();
                jQuery('body').removeClass('overlay overlay-show-share-this');
                window.open('https://twitter.com/intent/tweet?url=' + encodeURIComponent(window.shareData.url) + '&text=' + encodeURIComponent(window.shareData.summary), 'Twitter Share', 'height=450, width=550, top=' + share.getVertivalMid() + ', left=' + share.getHorizontalMid() + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
            });
            jQuery('.share-menu .facebook').on('click', function (e) {
                e.preventDefault();
                jQuery('body').removeClass('overlay overlay-show-share-this');
                window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.shareData.url), 'Facebook Share', 'height=450, width=550, top=' + share.getVertivalMid() + ', left=' + share.getHorizontalMid() + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
            });
            jQuery('.share-menu .mail').on('click', function (e) {
                e.preventDefault();
                jQuery('body').removeClass('overlay overlay-show-share-this');
                window.location.href = 'mailto:?subject=' + encodeURIComponent(window.shareData.title) + '&body=' + encodeURIComponent(window.shareData.url);
            });
        }     
    },
    init: function () {
        share.showShareMenu();
        jQuery('.share-menu__close').on('click', share.hideShareMenu);
        jQuery(window).resize(share.hideShareMenu);
        share.initSocialNetworks();

        window.addEventListener('mon-script-loaded', function () {
            share.setupMonsidoTracking();
        });
    }
}

export default share;


