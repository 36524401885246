import jQuery from "jquery"

const Accordion = {    
    setupOnClickEvents: function () {     
        var pagename = jQuery("meta[name='pagename']").attr("content");
        if (pagename !== "") {     
            jQuery(".accordion-link-item").each(function (index, element) {
                var linktext = jQuery(element).data('accordionlinktext');
                if (linktext !== undefined && pagename !== undefined) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Link', 'Accordion Link to " + linktext + "', 'Click Accordion link on " + pagename + " page', 1);");
                }
            });

            jQuery(".announcement-link").each(function (index, element) {
                var linkentry = jQuery(element).data('linkentry');
                if (linkentry !== undefined && pagename !== undefined) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Link', 'Link Entry to " + linkentry + "', 'Click Link Entry on " + pagename + " page', 1);");
                }
            });

            jQuery(".announcement-download").each(function (index, element) {
                var extension = jQuery(element).data("extension");
                var documentTitle = jQuery(element).data("documenttitle");
                var documentLanguage = jQuery(element).data("documentlanguage");
                if (documentTitle !== undefined && extension !== undefined) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Download', 'Download Entry " + extension + " - " + documentTitle + " - " + documentLanguage + "', 'Click Download on " + pagename + " page', 1);");
                    jQuery(element).addClass("monsido_download");
                }
            });

            jQuery(".accordion-file-link").each(function (index, element) {
                var extension = jQuery(element).data("extension");
                var documentTitle = jQuery(element).data("documenttitle");
                var documentLanguage = jQuery(element).data("documentlanguage");
                if (documentTitle !== undefined && extension !== undefined) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Download',  'Download File " + extension + " - " + documentTitle + " - " + documentLanguage + "', 'Click Download File on " + pagename + " page', 1);");
                    jQuery(element).addClass("monsido_download");
                }
            });

            jQuery(".single-accordion").each(function (index, element) {
                var extension = jQuery(element).data("extension");
                var documentTitle = jQuery(element).data("documenttitle");
                var documentLanguage = jQuery(element).data("documentlanguage");
                var linkHref = jQuery(element).data("linkhref");
                if (documentTitle !== undefined && extension !== undefined) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Download', 'Accordion Download " + extension + "- " + documentTitle + "- " + documentLanguage + "', 'Click Accordion on " + pagename + " page', 1);");
                    jQuery(element).addClass("monsido_download");
                }
                else {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Link', 'Accordion Link to " + linkHref + "', 'Click Accordion Link on " + pagename + " page', 1);");
                }
            });  

            jQuery('.accordion > h3').click(function () {
                //Monsido event tracking
                var h3 = jQuery(this).contents().filter(function () {
                    return this.nodeType == 3;
                }).text().trim();  
                if (h3 !== "") {
                    window.monsido_functions.trackEvent('Accordion', 'Expand accordion', 'Expand accordion ' + h3 + ' on page ' + pagename, 1);
                    //console.log(h3);
                }
               
            });
        }
    },

    inEditorMode: function () {
        if (jQuery(this).next().is(':visible')) {
            jQuery(this).next().slideDown();
            jQuery(this).addClass('accordion-visible');
        }
    },
    setSlide: function () {

        var allPanels = jQuery('.accordion > .accordion-insides:not(.visible)').hide();
        jQuery('.accordion > h3').click(function () {
            allPanels.slideUp();
            jQuery('.accordion > h3').removeClass('accordion-visible');

            if (jQuery(this).next().is(':visible')) {
                jQuery(this).next().slideUp();

                jQuery(this).removeClass('accordion-visible');

            } else {
                jQuery(this).next().slideDown();                
                jQuery(this).addClass('accordion-visible');
                                
            }
        });
    },
    init: function () {
        if (jQuery('.pagemode-edit').length === 1) {
            Accordion.inEditorMode();
        } else {
            Accordion.setSlide();              
        }
       
        window.addEventListener('mon-script-loaded', function () {
            Accordion.setupOnClickEvents();
        });
    }
}
export default Accordion;



