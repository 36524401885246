import jQuery from "jquery"

const navigation = {   
    navigationSearch: function () {        
        jQuery("#navigation .btn-search").click(function () {          
            jQuery("#navigation .navbar-search form").slideToggle("500", "easeInOutCirc");    
            jQuery("#searching").focus();           
        });

        jQuery('#navigation .nav-item_search-btn').click(function () {
            var term = jQuery('#navigation .nav_search-textbox').val();
            var searchUrl = jQuery('#navigation .nav-item_search-btn').data('searchurl');
            if (term === undefined) return;
            if (searchUrl === undefined) return;
            event.preventDefault();
            var url = searchUrl + "?term=" + term;
            document.location = url;     

        });
    },   

    setupClickEvents: function () {           
        jQuery('#navigation .nav-item_search-btn').on('click',function (event) {
            navigation.navigationSearch(event,'desktop');
        });

        jQuery('.dropdown').on('show.bs.dropdown', function () {
            jQuery(this).find('.dropdown-menu').first().slideToggle();
            jQuery('.primary-link-box').css("display",'block');
            if (jQuery('.primary-link-box').css("right") == "-=300") {
                jQuery('.primary-link-box').animate({ "right": '0' });
            }
        });
       
        jQuery('.dropdown').on('hide.bs.dropdown', function (e) {
            e.preventDefault();
            jQuery(this).find('.dropdown-menu').first().slideToggle();
            jQuery('.dropdown').removeClass('show');
            jQuery('.dropdown-menu').removeClass('show');
            jQuery('.primary-link-box').css("display", 'none');
        });
    },
    setupOnClickEvents: function () {        
        var pagename = jQuery("meta[name='pagename']").attr("content");
        if (pagename!=="") {
            jQuery(".quicklink-link").each(function (index, element) {
                var extension = jQuery(element).data("extension");
                var documentTitle = jQuery(element).data("documenttitle");
                var documentLanguage = jQuery(element).data("documentlanguage");              
                if (documentTitle !== undefined && extension !== undefined) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Download', 'Quicklink Download " + extension + "- " + documentTitle + "- " + documentLanguage + "', 'Click on " + pagename + " page', 1);");
                    jQuery(element).addClass("monsido_download");
                }   
            });  

            jQuery(".langlink").each(function (index, element) {
                var linkHref = jQuery(element).attr("href");         

                if (linkHref !== undefined) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Link', 'Language Chooser Link in topnavigation to " + linkHref + "', 'Click on " + pagename + " page', 1);");
                }
            }); 

            jQuery(".btn-search").each(function (index, element) {   
                jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Search', 'Search icon in topnavigation', 'Click Search icon on " + pagename + " page', 1);");                
            }); 

            jQuery(".commercelink").each(function (index, element) {
                jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Link', 'Shop link in topnavigation', 'Click Shop icon on " + pagename + " page', 1);");
            }); 
            jQuery(".primary-link").each(function (index, element) {
                jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Navigation', 'Click on primary navigation', 'Navigation Click on " + jQuery(element).text().trim() + " page', 1);");
            }); 
            jQuery(".secondary-link").each(function (index, element) {
                jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Navigation', 'Click on secondary navigation', 'Navigation Click on " + jQuery(element).attr("href") + " page', 1);");
            });
            jQuery(".tertiary-link").each(function (index, element) {
                jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Navigation', 'Click on tertiary navigation', 'Navigation Click on " + jQuery(element).attr("href") + " page', 1);");
            });
        }
    },

    init: function () {
        jQuery(document).ready(function () {
            navigation.navigationSearch();
            navigation.setupClickEvents();
        });
      
        window.addEventListener('mon-script-loaded', function () {
            navigation.setupOnClickEvents();
        });
    }
}
export default navigation;
