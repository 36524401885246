﻿import jQuery from "jquery"

const formSecurity = {
 

    validatedInput: function () {
        jQuery(document).on('click', ':submit', function (e) {
            var fieldVal= jQuery(".input-field-for-contact input").val();
            var textFieldInput = jQuery('.text-field-for-input input').val();
          
            var first = textFieldInput.indexOf("noreply@chr-hansen.com") >= 0;
            var second = fieldVal.length === 0;
      
            if (!first || !second) {
                e.preventDefault(); //prevent the default action
                jQuery(this).closest("div").text("Thank you!");
                jQuery(this).closest("form").hide(1000);
            }
        });
    },
    
    validationManagement: function (e) {
        var textFieldValidation = jQuery('.text-field-for-input');
        if (textFieldValidation.length) {
         
            formSecurity.validatedInput();
        }
    },

    init: function () {
        jQuery(document).ready(function() {
            formSecurity.validationManagement();
        });
    }
}

export default formSecurity;
