import jQuery from "jquery"


const globalNavigation = {
    globalNavigationSearch: function () {
       
        jQuery("#globalNavigation .btn-search").click(function () {          
            jQuery("#globalNavigation .navbar-search form").slideToggle("500", "easeInOutCirc");                 
        });

        jQuery('#globalNavigation .nav-item_search-btn').click(function () {
            var term = jQuery('#globalNavigation .nav_search-textbox').val();
            var searchUrl = jQuery('#globalNavigation .nav-item_search-btn').data('searchurl');
            if (term === undefined) return;
            if (searchUrl === undefined) return;
            event.preventDefault();
            var url = searchUrl + "?term=" + term;

            document.location = url;   
        });
    },   

    setupClickEvents: function () {           
        jQuery('#globalNavigation .nav-item_search-btn').on('click',function (event) {
            globalNavigation.globalNavigationSearch(event,'desktop');
        });
    },

    init: function () {
        jQuery(document).ready(function () {
            globalNavigation.globalNavigationSearch();
            globalNavigation.setupClickEvents();
        });
    }
}

export default globalNavigation;
