﻿import jQuery from "jquery"
import Cookies from "../../../../Foundation/SoftGate/code/scripts/Foundation-SoftGate-Cookies.js";

const countryLocation = {
    setupClickEvents: function() {
        jQuery('#close-countrychooser').click(function() {
            //Ensure the Country chooser is not shown when navigating the website after closing the Country Chooser
            countryLocation.setCookie();
            jQuery('#countrychooser').hide();
        });
        jQuery('.countrychooser-link').click(function() {
            //Ensure the Country chooser is not shown when clicking the language link
            jQuery('#countrychooser').hide();
            countryLocation.setCookie();
        });
    },
    getVisitorCountryData: function() {
        jQuery(function() {
            var container = jQuery('#countrychooser');       
            var parameters = {
                DatasourceID: container.data('datasourceid'),
                ContextItem: container.data('item-id'),
                Path: container.data('path'),
                Sitename: container.data('site')
            };
            jQuery.ajax({
                type: "GET",
                url: "/api/market/GetCountryChooser",
                data: jQuery.param(parameters),
                success: function(response) {
                    if (response != null && response.Text != null && response.Link != null && response.Flag != null) {
                        jQuery("#visitorCountryText").prepend(response.Text);
                        jQuery("#visitorCountryFlag").attr("src", response.Flag);
                        jQuery("#visitorCountryFlag").attr("alt", response.FlagAltText);
                        jQuery("#visitorCountryLink").attr("href", response.Link);
                        jQuery('#countrychooser').show();
                    } else {
                        jQuery('#countrychooser').hide();
                    }
                },
                failure: function(response) {
                    //console.log(response.responseText);
                    jQuery('#countrychooser').hide();
                },
                error: function(response) {
                    //console.log(response.responseText);
                    jQuery('#countrychooser').hide();
                }
            });
        });
    },
    getCookieValue: function(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    setCookie: function() {
        Cookies.SetCookie("Country-Selected", "True");
    },
    init: function() {
        jQuery(document).ready(function() {
            countryLocation.setupClickEvents();
            var countryChooser = jQuery('#countrychooser');
            var countryCookieValue = countryLocation.getCookieValue("Country-Selected");
            // cookie exist or countrychooser element not in DOM (eg. disabled)
            if (countryCookieValue !== null || countryChooser.length === 0) {
                jQuery('#countrychooser').hide();
            } else {
                countryLocation.getVisitorCountryData();
            }
        });
    }
}
export default countryLocation;
