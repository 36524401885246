﻿import jQuery from 'jquery'
import contentSearch from '../../../../Foundation/ContentSearch/code/Scripts/Foundation-ContentSearch-Base.js'

const newsRoom = {
    
    
    tagTemplate: jQuery('#newsroom-template-tag'),
    itemTemplate: jQuery('#newsroom-template-item'),
    filterResultsBaseUrl: '/api/feature/News/NewsRoom/NewsRoomFilterResult',
    loadMoreResultsBaseUrl: '/api/feature/News/Newsroom/NewsRoomLoadMoreResults',
    
    createResultItem: function (resultItem, itemTemplate, tagTemplate) {
        var clone = jQuery(itemTemplate.html());
        clone.find('a').attr('href', resultItem.Url);
        clone.find('img').attr('src', resultItem.ImageUrl).attr('alt', resultItem.ImageAltText);
        clone.find('h3').html(resultItem.Headline);
        if (resultItem.MainTag) {
            clone.find('.newsroom-results-item-maintag').text(resultItem.MainTag.Name);
        } else {
            clone.find('.newsroom-results-item-maintag').remove();
        }
        clone.find('.newsroom-results-item-publishdate').text(resultItem.PublishDate);

        jQuery.each(resultItem.Tags,
            function(index, value) {
                var tagClone = jQuery(tagTemplate.html());
                tagClone.text(value.Name);
                clone.find('.newsroom-results-item-taglist').append(tagClone);
            });

        return clone;
    },
    
    initContentSearch: function () {
        contentSearch.filterResultsBaseUrl = newsRoom.filterResultsBaseUrl;
        contentSearch.loadMoreResultsBaseUrl = newsRoom.loadMoreResultsBaseUrl;
        contentSearch.tagTemplate = newsRoom.tagTemplate;
        contentSearch.itemTemplate = newsRoom.itemTemplate;
        contentSearch.createResultItem = newsRoom.createResultItem;
        contentSearch.scapikey = "{1E7F95BB-5E8B-431C-96F0-57438881063C}";
    },
    init: function () {
	    if (jQuery('.newsroom').length !== 0) {
		    newsRoom.initContentSearch();
		    contentSearch.setClickEvents();
		    contentSearch.filterResults();
	    }
    }
}

export default newsRoom;
