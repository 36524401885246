﻿import jQuery from "jquery";
const textTracking = {
    setupOnClickEvents: function () {      
        var pagename = jQuery("meta[name='pagename']").attr("content");
        if (pagename!=="") {
            var pagename = jQuery("body").data('pagename');
            jQuery(".page-content a").each(function (index, element) {
                var href = jQuery(element).attr("href");
                if (!href) return;
                let url = href.split('?')[0] 
                                     
                if (url.indexOf("/_/media/files") >= 0) {                       
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Download', 'Rich Text Content Download link " + url + "', 'Click on " + pagename + " page', 1);");
                    jQuery(element).addClass("monsido_download");
                }                 
            });   
            jQuery(".content-highlight a").each(function (index, element) {
                var href = jQuery(element).attr("href");
                if (!href) return;
                let url = href.split('?')[0]

                if (url.indexOf("/_/media/files") >= 0) {
                    jQuery(element).attr("onclick", "window.monsido_functions.trackEvent('Download', 'Rich Text Highlight Download link " + url + "', 'Click on " + pagename + " page', 1);");
                    jQuery(element).addClass("monsido_download");
                }
            });   
        }       
    },

    init: function () {
        window.addEventListener('mon-script-loaded', function () {
            textTracking.setupOnClickEvents();
        });
    }
};
export default textTracking
