import jQuery from "jquery"

const inPageNavigation = {
    setScroll: function () {
        jQuery(".inpage-navbar__link").click(function () {
            const e = jQuery(this);
            const $target = jQuery('section[data-inpagenav-title=\"' + e.text() + '\"' + ']');
            if ($target.length <= 0) return false;
            jQuery('html, body').animate({
                scrollTop: ($target.offset().top - 54)
            }, 1000, "easeInOutExpo");
            return false;
        });
    },
    buildNavigation: function () {
        var inPageNavElements = jQuery("*[data-inpagenav-title]");
        if (inPageNavElements.length <= 0) return;
        const maxNoOfNavItems = 2;
        var elements = "";
        for (let i = 0; i < inPageNavElements.length && i < maxNoOfNavItems; i++) {
            const $e = jQuery(inPageNavElements[i]);
            elements += "<li class=\"inpage-navbar__item\"><a href=\"#\" class=\"inpage-navbar__link\">" + $e.data("inpagenav-title") + "</a></li>";
        }
        var container = "<nav class=\"inpage-navbar\" id=\"inpageNavigation\"><ul class=\"nav inpage-navbar__list\">" + elements + "</ul></nav>";
        jQuery("main").append(container);
    },
    init: function () {
        inPageNavigation.buildNavigation();
        inPageNavigation.setScroll();
    }
}

export default inPageNavigation;
