﻿import jQuery from "jquery"

const cookiePolicy = {

    SetCookie: function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    GetCookie: function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return undefined;
    },

    SetOnClick: function () {
        var $cookiePolicy = jQuery(".cookiepolicy");
        var acceptCookie = $cookiePolicy.find(".cookie-accept");
      
        acceptCookie.click(function (e) {
            e.preventDefault();
            const $cookiePolicy = jQuery(".cookiepolicy");
            cookiePolicy.SetCookie("cookiepolicyShown", "false", 730);
            const cookieContentHeight = $cookiePolicy.find('.row').outerHeight();
            
            $cookiePolicy.animate({
                height: "-=" + cookieContentHeight
            }, 600, function() {
                $cookiePolicy.remove();
            });
           
        });

       
    },

    ShowCookiePolicy: function () {
        const cookieIsSet = cookiePolicy.GetCookie("cookiepolicyShown") !== undefined;
        if (!cookieIsSet) {
            const $cookiePolicy = jQuery(".cookiepolicy");
           
            $cookiePolicy.css({display: 'block'});
            //const cookieContentHeight = $cookiePolicy.find('.row').outerHeight();
            //$cookiePolicy.animate({
            //    height: "+=" + cookieContentHeight
            //}, 600);
           
        }
    },    

    init: function () {
         cookiePolicy.SetOnClick();
         cookiePolicy.ShowCookiePolicy();        
    }

}

export default cookiePolicy;
