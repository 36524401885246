﻿
const softGateCookies = {
    PrivateVisitorType: 2,
    PublicVisitorType: 1,
    SetCookie: function (cname, cvalue) {
        document.cookie = cname + "=" + cvalue + ";path=/";
    },
    SetPublicCookie: function(cname) {
        softGateCookies.SetCookie(cname, softGateCookies.PublicVisitorType);
    },
    SetPrivateCookie: function(cname) {
        softGateCookies.SetCookie(cname, softGateCookies.PrivateVisitorType);
    },
    GetCookie: function(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    IsPrivateVisitor: function(cname) {
        var visitorType = parseInt(softGateCookies.GetCookie(cname), 10);
        if (visitorType === softGateCookies.PrivateVisitorType)
            return true;

        return false;
    }
}
export default softGateCookies;
