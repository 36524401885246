﻿import jQuery from "jquery";

const persontracking = {
    setupOnClickEvents: function () { 
        var pagename = jQuery("meta[name='pagename']").attr("content");
        if (pagename!=="") {
            jQuery(".person-location-url").each(function (index, element) {               
                var href = jQuery(element).attr('href');
                if (pagename !== undefined && href !== undefined) {
                    jQuery(element).attr('onclick', "window.monsido_functions.trackEvent('Person Module Link', 'Link to "+href+"', 'Click on " + pagename + " page', 1);");
                }
            });    
            jQuery(".person-contact-link").each(function (index, element) {              
                var href = jQuery(element).attr('href');
                if (pagename !== undefined && href !== undefined) {
                    jQuery(element).attr('onclick', "window.monsido_functions.trackEvent('Person Contact Link', 'Link to " + href + "', 'Click on " + pagename + " page', 1);");
                }
            });   
        } 
    },

    init: function () {       
        window.addEventListener('mon-script-loaded', function () {
            persontracking.setupOnClickEvents();
        });
    }
};

export default persontracking



