import jQuery from 'jquery'

const filterTypes = {
    TAGS: 'tags',
    YEAR: 'year',
    MONTH: 'month'
};

const filterCategorySelector = '#contentSearch-filter-dropdown-';
const suggestorBaseUri = '/api/foundation/contentsearch/GetSuggestions';
const monsidoEventTrackingCategoryField = 'data-monsido-category';
const monsidoEventTrackingActionField = 'data-monsido-action';
const monsidoEventTrackingEventField = 'data-monsido-event';

function showHideLoadMore(hide) {
    const button = jQuery(".contentSearch-btn-loadmore");
    if (hide) {
        button.hide();
    } else {
        button.show();
    }
}

function showHideSelectedFilters(hide) {
    const filters = jQuery(".contentSearch-clear-selected-filters > button");
    if (hide) {
        filters.addClass('d-none');
    } else {
        filters.removeClass('d-none');
    }
}

function renderSuggestions(matchedSuggestions, term) {
    var suggestionsContainer = jQuery('#contentSearch-suggestions');
    suggestionsContainer.empty();

    jQuery.each(matchedSuggestions,
        function (index, suggestion) {
            if (suggestion === term) return;

            const text = suggestion.replace(new RegExp(term, 'gi'), '<strong>$&</strong>');
            const listItem = jQuery('<li>');
            listItem.html(text);

            suggestionsContainer.append(listItem);
        });

    if (matchedSuggestions.length > 0) {
        suggestionsContainer.show();
    } else {
        suggestionsContainer.hide();
    }

}

function monsidoSearchTracking(searchTerms, noOfResults) {
    const terms = searchTerms.trim();
    if (window.monsido_functions && terms !== "") {
        window.monsido_functions.trackSearch(terms, noOfResults);
    }
}

function monsidoEventTracking(eventCategory, eventAction, event) {
    if (window.monsido_functions) {
        window.monsido_functions.trackEvent(eventCategory, eventAction, event);
    }
}

function clearSelectedFilters() {
    const panel = jQuery('#contentSearch-selected-filters');
    var selectedFilters = panel.find('.contentSearch-selected-filters');
    selectedFilters.empty();
    contentSearch.year = null;
    contentSearch.month = null;
    contentSearch.filterResults();
}

function setFilterEvents() {
    jQuery('.contentSearch [data-fid]').on('click', contentSearch.filterOnClick);
    jQuery('.contentSearch [data-sfid]').on('click', contentSearch.selectedFilterOnClick);
    jQuery('.contentSearch [data-sy]').on('click', resetYear);
    jQuery('.contentSearch [data-sm]').on('click', resetMonth);
    jQuery('.contentSearch [data-year]').on('click', contentSearch.selectYearOnClick);
    jQuery('#contentSearch-results > div > ul > li > a').on('click',
	    function() {
            const element = jQuery(this);
            if (contentSearch.enableMonsidoTracking) {
                monsidoEventTracking(element.data('monsido-category'),
                    element.data('monsido-action'),
                    element.data('monsido-event'));
            }
	    });

    /*jQuery('.contentSearch [data-month]').on('click', contentSearch.selectMonthOnClick);*/
}

function getSearchTerm() {
    return jQuery('.contentSearch-term').val();
}

function resetYear() {
    contentSearch.year = null;
    contentSearch.filterResults();
}

function resetMonth() {
    contentSearch.month = null;
    contentSearch.filterResults();
}

const contentSearch = {
    pageNo: 0,
    year: null,
    month: null,
    sitename: jQuery('.contentSearch').data('sitename'),
    language: jQuery('.contentSearch').data('language'),
    filterPattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
    filterTemplate: jQuery("#contentSearch-template-filter"),
    selectedFilterTemplate: jQuery('#contentSearch-template-selected-filter'),
    tagTemplate: null,
    itemTemplate: null,
    createResultItem: null,
    filterResultsBaseUrl: null,
    loadMoreResultsBaseUrl: null,
    enableMonsidoTracking: false,
    monsidoEventTrackingCategory: null,
    scapikey: null,
    getSelectedFilters: function () {
        return jQuery('.contentSearch-selected-filters [data-sfid]').map(function () {
            return jQuery(this).data('sfid');
        }).get();
    },

    filterResults: function (filters) {
        if (!filters) filters = contentSearch.getSelectedFilters();

        contentSearch.getFilterResults(filters);
    },

    loadMore: function (filters) {
        contentSearch.pageNo++;
        const url = contentSearch.buildUrl(contentSearch.filterResultsBaseUrl, filters);
        contentSearch.sendQuery(url, contentSearch.displayMoreResults);
    },

    getFilterResults: function (filters) {
        contentSearch.pageNo = 0;
        const url = contentSearch.buildUrl(contentSearch.filterResultsBaseUrl, filters);
        contentSearch.sendQuery(url, contentSearch.displayResults);
    },

    displayResults: function (results) {
        contentSearch.removeLoadMoreEvent();
        contentSearch.setFilters(results);
        contentSearch.addItems(results, true);
        contentSearch.addLoadMoreEvent();
        setFilterEvents();
    },

    displayMoreResults: function (results) {
        contentSearch.addItems(results, false);
    },

    setFilters: function (results) {
        contentSearch.setSelectedFilters(results.SelectedFilters,
            results.SelectedYear,
            results.SelectedMonth,
            results.SelectedMonthText,
            contentSearch.selectedFilterTemplate);

        jQuery.each(results.Filters,
            function (index, value) {
                const selector = filterCategorySelector + value.Id;
                contentSearch.updateFilters(selector,
                    'data-fid',
                    value.Tags,
                    contentSearch.filterTemplate,
                    filterTypes.TAGS,
                    value.Id);
            });

        var selector = filterCategorySelector + 'year';
        contentSearch.updateFilters(selector,
            'data-year',
            results.Years,
            contentSearch.filterTemplate,
            filterTypes.YEAR);

        //selector = filterCategorySelector + 'month';
        //contentSearch.updateFilters(selector,
        //    'data-month',
        //    results.Months,
        //    contentSearch.filterTemplate,
        //    filterTypes.MONTH);

    },

    addFilter: function (filter) {
        // Add a filter to the selected filters.
        const filters = contentSearch.getSelectedFilters();
        if (contentSearch.filterPattern.test(filter)) {
            filters.push(filter);
        }
        return filters;
    },

    removeFilter: function (filter) {
        // Removes a filter from selected filters.
        var selectedFilters = contentSearch.getSelectedFilters();

        if (contentSearch.filterPattern.test(filter)) {
            selectedFilters = jQuery.grep(selectedFilters, function (value) {
                return value !== filter;
            });
        }

        return selectedFilters;
    },

    yearSelected: function (element) {
        // Sets the selected year.
        const year = element.data('year');
        contentSearch.year = year;
    },
    monthSelected: function (element) {
        // Sets the selected month.
        const month = element.data('month');
        contentSearch.month = month;
    },

    disableFilterDropdown: function (element) {
        element.prop('disable', true);
        element.addClass('disabled');
    },

    updateFilters: function (panelSelector, dataField, filters, template, type, filterId) {
        const panel = jQuery(panelSelector);
        const button = panel.find('.dropdown-toggle');
        const options = panel.find('.dropdown-menu');

        // Empty dropdown options
        options.empty();

        if ((filters && filters.length === 0) || (type === filterTypes.YEAR && contentSearch.year !== null) || (type === filterTypes.MONTH && contentSearch.month !== null)) {
            button.prop('disable', true);
            button.addClass('disabled');
            return;
        }

        jQuery.each(filters,
            function (index, value) {
                if (type === filterTypes.TAGS) {
                    contentSearch.setTagFilters(index, value, template, dataField, options, filterId);
                } else {
                    contentSearch.setDateFilters(index, value, template, dataField, options);
                }
            });

        button.prop('disable', true);
        button.removeClass('disabled');
    },



    setTagFilters: function (index, value, template, dataField, optionsElement, filterId) {
        const clone = jQuery(template.html());
        clone.attr(dataField, value.Id);
        clone.attr('title', value.Name);

        if (contentSearch.enableMonsidoTracking) {
            clone.attr(monsidoEventTrackingCategoryField, contentSearch.monsidoEventTrackingCategory);
            clone.attr(monsidoEventTrackingActionField, filterId);
            clone.attr(monsidoEventTrackingEventField, `Clicked ${value.Name} (${value.Id})`);
        }

        const text = `${value.Name} (${value.Count})`;
        clone.text(text);
        optionsElement.append(clone);
    },

    setDateFilters: function (index, value, template, dataField, options) {
        const clone = jQuery(template.html());
        clone.attr(dataField, index);
        clone.text(value);
        options.append(clone);
    },

    buildUrl: function (baseUrl, filters) {
        const term = getSearchTerm();
        const path = `${baseUrl}/${contentSearch.sitename}/${contentSearch.language}/${contentSearch.pageNo}`;
        var uri = new URL(path, window.location.origin);
        if (term) {
            uri.searchParams.append('term', term);
        }

        filters.forEach(element => {
            uri.searchParams.append('filters', element);
        });

        if (contentSearch.month) {
            uri.searchParams.append('month', contentSearch.month);
        }

        if (contentSearch.year) {
            uri.searchParams.append('year', contentSearch.year);
        }

        return uri;
    },
    sendQuery: function (url, callback) {
        jQuery.ajax({
            type: 'GET',
            url: url,
            headers: {"sc_apikey": contentSearch.scapikey},
            datatype: 'application/json',
            contentType: 'application/json; charset=utf-8',
            success: callback
        });
    },
    /*
    * Sets selected filters
    * @param {Array.<Object>} filters - Selected filter objects to be shown.
    * @param {int} year - Selected year.
    * @param {int} month - Selected month as a number.
    * @param {string} - Selected month display text.
    * @param {string} - jQuery selector for the selected filter template.
    */
    setSelectedFilters: function (filters, year, month, monthText, tagTemplate) {
        const panel = jQuery('#contentSearch-selected-filters');
        var selectedFilters = panel.find('.contentSearch-selected-filters');
        selectedFilters.empty();

        if (filters.length === 0 && year == null && month === null) {
            showHideSelectedFilters(true);
            return;
        }

        jQuery.each(filters, function (index, value) {
            const clone = jQuery(tagTemplate.html());
            clone.attr('data-sfid', value.Id);
            contentSearch.setSelectedFilterText(clone, value.Name);
            if (contentSearch.enableMonsidoTracking) {
                clone.attr(monsidoEventTrackingCategoryField, contentSearch.monsidoEventTrackingCategory);
                clone.attr(monsidoEventTrackingActionField, 'Removed Selected Filter');
                clone.attr(monsidoEventTrackingEventField, `Removed ${value.Name} (${value.Id})`);
            }
            selectedFilters.append(clone);
        });

        if (year !== null) {
            const yearClone = jQuery(tagTemplate.html());
            yearClone.attr('data-sy', year);
            contentSearch.setSelectedFilterText(yearClone, year);
            selectedFilters.append(yearClone);
        }

        if (month !== null) {
            const monthClone = jQuery(tagTemplate.html());
            monthClone.attr('data-sm', month);
            contentSearch.setSelectedFilterText(monthClone, monthText);
            selectedFilters.append(monthClone);
        }

        showHideSelectedFilters(false);
    },

    addItems: function (results, clear) {
        var resultsList = jQuery('#contentSearch-results ul');

        if (clear) {
            resultsList.empty();
        }

        jQuery.each(results.Items,
            function (index, value) {
                resultsList.append(contentSearch.createResultItem(value, contentSearch.itemTemplate, contentSearch.tagTemplate));
            });

        jQuery('#contentSearch-numberofresults')[0].textContent = results.LabelNumberOfResults;

        if (results.NumberOfResults === results.TotalResults) {
            showHideLoadMore(true);
        } else {
            showHideLoadMore(false);
        }

        monsidoSearchTracking(getSearchTerm(), results.NumberOfResults);
    },

    setSelectedFilterText: function (object, value) {
        object.contents().filter(function () { return this.nodeType == 3; }).first().replaceWith(value);
    },

    filterOnClick: function (callback) {
        const element = jQuery(this);
        const filter = element.data('fid');

        if (contentSearch.enableMonsidoTracking) {
            monsidoEventTracking(element.data('monsido-category'),
                element.data('monsido-action'),
                element.data('monsido-event'));
        }

        const filters = contentSearch.addFilter(filter);
        contentSearch.filterResults(filters);
    },

    selectedFilterOnClick: function () {
        const element = jQuery(this);
        const filter = element.data('sfid');

        if (contentSearch.enableMonsidoTracking) {
            monsidoEventTracking(element.data('monsido-category'),
                element.data('monsido-action'),
                element.data('monsido-event'));
        }

        const filters = contentSearch.removeFilter(filter);
        contentSearch.filterResults(filters);
    },

    selectYearOnClick: function () {
        const element = jQuery(this);
        contentSearch.yearSelected(element);
        contentSearch.filterResults();
    },

    selectMonthOnClick: function () {
        const element = jQuery(this);
        contentSearch.monthSelected(element);
        contentSearch.filterResults();
    },



    loadMoreOnClick: function () {
        contentSearch.loadMore(contentSearch.getSelectedFilters(), getSearchTerm());
    },

    addLoadMoreEvent: function () {
        jQuery('.contentSearch .contentSearch-btn-loadmore').on('click', contentSearch.loadMoreOnClick);
    },

    removeLoadMoreEvent: function () {
        jQuery('.contentSearch .contentSearch-btn-loadmore').off('click', contentSearch.loadMoreOnClick);
    },

    initSearch: function () {
        contentSearch.filterResults();
    },

    getSuggestedSearchTerm: function () {
        const term = getSearchTerm();
        if (term.length >= 2) {
            var uriString = `${suggestorBaseUri}/${contentSearch.language}`;
            const uri = new URL(uriString, window.location.origin);
            uri.searchParams.append('term', term);
            contentSearch.sendQuery(uri, contentSearch.suggestedSearchTermCallback);
        } else {
            jQuery('#contentSearch-suggestions').hide();
        }
    },

    suggestedSearchTermCallback: function (data) {
        renderSuggestions(data, getSearchTerm());
        jQuery('#contentSearch-suggestions').on('click', 'li', function () {
            const clickedSuggestion = jQuery(this).text();
            jQuery('#contentSearch-input').val(clickedSuggestion);
            jQuery('#contentSearch-suggestions').hide();
        });
    },

    setClickEvents: function () {
        setFilterEvents();

        jQuery('#contentSearch-search-button').on('click', contentSearch.initSearch);
        jQuery('.contentSearch-clear-selected-filters > button').on('click', clearSelectedFilters);
        jQuery('#contentSearch-input').unbind('keyup').bind('keyup',
            (e) => {
                const keyCode = e.keyCode || e.which;
                if (keyCode === 13) {
                    contentSearch.pageNo = 0;
                    contentSearch.initSearch();
                } //else {
                //	contentSearch.getSuggestedSearchTerm();
                //}
            });
        contentSearch.addLoadMoreEvent();
    }
}

export default contentSearch;
