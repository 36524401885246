﻿import jQuery from 'jquery';
import contentSearch from '../../../../Foundation/ContentSearch/code/Scripts/Foundation-ContentSearch-Base.js';

const tagSearch = {
	tagTemplate: jQuery('#tagsearch-template-tag'),
	itemTemplate: jQuery('#tagsearch-template-item'),
	filterResultsBaseUrl: '/api/feature/Search/TagSearch/TagSearchResult',

	createResultItem: function (resultItem, itemTemplate, tagTemplate) {
		const clone = jQuery(itemTemplate.html());
		clone.find('a').attr('href', resultItem.Url);

		if (contentSearch.enableMonsidoTracking) {
			clone.find('a')
				.attr('data-monsido-category', contentSearch.monsidoEventTrackingCategory)
				.attr('data-monsido-action', 'Search Result Click')
				.attr('data-monsido-event', resultItem.Url);

		}

		clone.find('img').attr('src', resultItem.ImageUrl).attr('alt', resultItem.ImageAltText);
		clone.find('h3').html(resultItem.Headline);
		if (resultItem.MainTag) {
			clone.find('.tagsearch-result-item-maintag').text(resultItem.MainTag.Name);
		} else {
			clone.find('.tagsearch-result-item-maintag').remove();
		}

		clone.find('.tagsearch-result-item-breadcrumb').html(resultItem.Breadcrumb);
		clone.find('.tagsearch-result-item-description').html(resultItem.Abstract);

		//jQuery.each(resultItem.Tags,
		//	function(index, value) {
		//		var tagClone = jQuery(tagTemplate.html());
		//		tagClone.text(value.Name);
		//		clone.find('.tagsearch-result-item-taglist').append(tagClone);
		//	});

		return clone;
	},

	initContentSearch: function () {
		contentSearch.filterResultsBaseUrl = tagSearch.filterResultsBaseUrl;
		contentSearch.tagTemplate = tagSearch.tagTemplate;
		contentSearch.itemTemplate = tagSearch.itemTemplate;
		contentSearch.createResultItem = tagSearch.createResultItem;
		contentSearch.enableMonsidoTracking = true;
		contentSearch.monsidoEventTrackingCategory = 'Search';
		contentSearch.scapikey = "{3582CBE0-313A-4C21-BFA1-2288BC69A2B3}";
	}, 

	init: function () {
		if (jQuery('.tagsearch').length !== 0) {
			var term = jQuery.QueryString["term"];
			if (term) {
				jQuery('#contentSearch-input').val(term);
			}
			tagSearch.initContentSearch();
			contentSearch.setClickEvents();
			contentSearch.filterResults();
		}
	}
}

export default tagSearch;
