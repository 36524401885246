import jQuery from "jquery"

const printshare = {
    setupOnClickEvents: function () {
        var pagename = jQuery("meta[name='pagename']").attr("content");
        if (pagename !== "") {                      
            jQuery("a.print").each(function (index, element) {
                if (pagename !== undefined) {
                    jQuery(element).attr('onclick', "window.monsido_functions.trackEvent('Print', 'Click on Print', 'Click on Print button on  " + pagename + " page', 1);");
                }
            });
        }
    },
  
    print: function () {       
        window.print();
      /*  return false;*/

    },
    init: function () {
        window.addEventListener('mon-script-loaded', function () {
            printshare.setupOnClickEvents();
        });
        var navbar = jQuery("body").find(".inpage-navbar").last("not[class='.page-actions']");
        var pageActions = jQuery("body").find(".inpage-navbar.page-actions");
        if (navbar != null && pageActions != null) {
            pageActions.insertAfter(navbar);
        }
        jQuery('a.print').click(function (e) {   
         
            print();
        });
    }
}

export default printshare;
