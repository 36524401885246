import jQuery from "jquery";

const contactinformation = { 
    setupOnClickEvents: function () {      
        var pagename = jQuery("meta[name='pagename']").attr("content");
        if (pagename!=="") {                
            jQuery(".footer-logo-link").each(function (index, element) {
                var logolink = jQuery(element).data('data-logolink');                         
                if (logolink !== undefined && pagename !== undefined) {                            
                    jQuery(element).attr('onclick', "window.monsido_functions.trackEvent('Footer Link', 'Logo website Link to " + logolink + " ', 'Click website link in footer on " + pagename + " page', 1);");
                }
            });
                                               
            jQuery(".contact_information__email_link").each(function (index, element) {                                 
                if (pagename !== undefined) {                             
                    jQuery(element).attr('onclick', "window.monsido_functions.trackEvent('Footer Link', 'Click Email Link', 'Click Contact Email in footer on " + pagename + " page', 1);");
                }
            });
                                                           
            jQuery(".social-link").each(function (index, element) {              
                var sociallink = jQuery(element).attr('href');             
                if (sociallink !== undefined && pagename !== undefined) {                   
                    jQuery(element).attr('onclick', "window.monsido_functions.trackEvent('Footer Link', 'Social Link to " + sociallink + " ', 'Click Social link in footer on " + pagename + " page', 1);");
                }
            });   
        } 
    },

  
    init: function () {
        window.addEventListener('mon-script-loaded', function () {
            contactinformation.setupOnClickEvents();
        });          
    }
};

export default contactinformation
