﻿import jQuery from "jquery"

const superScript = {
    format: function () {
       
        jQuery('body :not(script) :not(sup)').contents().filter(function () {
            return this.nodeType === 3;
        }).replaceWith(function () {
            return this.nodeValue.replace(/[™®©]/g, '<sup>$&</sup>');
        });
    },
   
    init: function () {
        jQuery(document).ready(function () {
            superScript.format();
        });
    }
}
export default superScript;
