import mainNavigation from "./Feature.Navigation.MainNavigation.js";
import inPageNavigation from "./Feature.Navigation.InPage.js";
import internalAnchor from "./Feature.Navigation.InternalHeading.js";
import navigation from "./Feature.Navigation.Navigation.js";
import globalNavigation from "./Feature.Navigation.GlobalNavigation.js";


navigation.init();
inPageNavigation.init();
internalAnchor.init();
mainNavigation.init();
globalNavigation.init();


