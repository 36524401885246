﻿const socialicons = {
    
    init: function () {
        window.addEventListener("DOMContentLoaded", function () {
            var svgSocialLink = document.getElementById("SocialSvgIcon");
            if(svgSocialLink === null) return;
            setTimeout(function () {
                svgSocialLink.style.visibility = "visible";
            }, 200);
        });
     
    }
}
export default socialicons;
