import jQuery from "jquery"
const videoMoa = {
   
init: function() {
        jQuery(document).ready(function () {
            var $videoSrc;
            jQuery('.video-btn').click(function () {
                $videoSrc = jQuery(this).data("src");
                jQuery("#videoMoa source").attr('src', $videoSrc);
                //console.log($videoSrc);
            });

            // when the modal is opened autoplay it  
            jQuery('#VideoMoaModal').on('show.bs.modal', function (e) {
                var myVideo = document.getElementById("videoMoa");
                myVideo.load();
                myVideo.play();
            });
 
            jQuery('#VideoMoaModal').on('hide.bs.modal', function (e) {
                var myVideo = document.getElementById("videoMoa");
                myVideo.pause();
            });

            jQuery("#videoMoa").on("ended", function () {
                jQuery('#VideoMoaModal').modal('hide');
            });
        });
    }
};
export default videoMoa;


