import jQuery from "jquery"
import lighSlider from "./lightslider.js"

const heroSlider = {
    init: function () {
        var $slider = jQuery('.heroslider__container .slider'),
            $sliderImg = $slider.find('.herospot__img');

        $slider.each(function () {
            if (jQuery('li', this).length > 1) {
                jQuery(this).lightSlider({
                    gallery: false,
                    item: 1,
                    pager: true,
                    controls: true,
                    loop: true,
                    thumbItem: $sliderImg.length,
                    slideMargin: 0,
                    enableDrag: true,
                    enableTouch: true,
                    galleryMargin: 0,
                    thumbMargin: 0,
                    auto: true,
                    speed: 600,
                    pause: 10000,
                    onBeforeSlide: function (el) {
                        jQuery(el).find('.is-active').removeClass('is-active');
                    },
                    onAfterSlide: function (el) {
                        setTimeout(function () {
                            jQuery(el).find('.active header').addClass('is-active');
                        }, 80);
                    },
                    onSliderLoad: function (el) {
                        $slider.addClass('is-active');
                        jQuery(el).find('.active header').addClass('is-active');
                    }
                });
            }
        });


    }
}
export default heroSlider;
