import jQuery from "jquery"

const newsList = {
   
    onPaginationClick: function (element) {      
        var $this = jQuery(element);
        var page = $this.data("page");
        var sitename = jQuery("#newslist").data("sitename");
        var language = jQuery("#newslist").data("language");
        var itemsperpage = jQuery("#newslist").data("itemsperpage");          
        var itemid = jQuery("meta[name=sitecore-item-id]").attr("content");
        /* console.log("page: " + page + " ----itemsperpage: " + itemsperpage + " --- sitename: " + sitename + " ----language: " + language + " ---- itemid: " + itemid);*/
        newsList.getNewsListResults(page, itemid, sitename, language, itemsperpage);
    },
    getNewsListResults: function (page, itemid, sitename, language, itemsperpage) {        
        var jsonObject = { 
            "Page": page,
            "ItemId": itemid, 
            "Sitename": sitename, 
            "Language": language,
            "ItemsPerPage": itemsperpage
        }
       
        jQuery.ajax({
            type: "POST",
            url: "/api/feature/news/NewsList/NewsList",
            datatype: "application/json",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(jsonObject),
            success: function (result) {            
                newsList.showResult(result);

            }
        });
    },
    showResult: function (result) {
        jQuery("#newslist").replaceWith(result);
        jQuery("#newslist").hide().fadeIn(1500);
		newsList.initPagination();
    },
  
    initPagination: function () {
        jQuery("#newslist_paging > ul").find("li > a").on("click", function (e) {   
            jQuery("#newslist").fadeOut(700); newsList.onPaginationClick(this, e); 
        });
    },
     
	init: function () {
		if (jQuery("#newslist").length !== 0)
		{
			newsList.initPagination();
			newsList.initResults();
		}
	},

	initResults: function () {
		var page = 1
		var sitename = jQuery("#newslist").data("sitename");
		var language = jQuery("#newslist").data("language");
		var itemsperpage = jQuery("#newslist").data("itemsperpage");
		var itemid = jQuery("meta[name=sitecore-item-id]").attr("content");
		/* console.log("page: " + page + " ----itemsperpage: " + itemsperpage + " --- sitename: " + sitename + " ----language: " + language + " ---- itemid: " + itemid);*/
		newsList.getNewsListResults(page, itemid, sitename, language, itemsperpage);
	}
}

export default newsList;
